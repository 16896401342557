import { graphql, useStaticQuery } from "gatsby"

const useSocialMedia = () => {

  const data = useStaticQuery(graphql`
    query {
      info: blockContentInfo(id: {eq: "6496c1c8-1299-5890-9147-c6806bd47ed5"}) {
        id
        address: field_address
        mail: field_mail
        phone: field_phone
        relationships {
          items: field_items {
            id
            image: field_image {
              alt
            }
            link: field_link {
              title
              uri
            }
            relationships {
              image: field_image {
                ...Image
              }
            }
          }
        }
      }
    }
  `)

  //console.log(data)

  const links = data.info.relationships.items.map((item, index) => {

    return {
      label: item.link.title,
      image: {
        src: item.relationships.image.localFile.publicURL,
        alt: item.image.alt
      },
      url: item.link.uri
    }

  })
  return {
    address: data.info.address,
    mail: data.info.mail,
    phone: data.info.phone,
    links: links
  }
}

export default useSocialMedia
