import React from "react";
import Layout from "../components/layouts";
import SectionGrid from "../components/sections/grid/grid";
import HeroInner2 from "../components/sections/hero/inner2";
import { Title, SubTitle } from "../components/atoms/titles";
import { Description, Html } from "../components/atoms/text";
import FormContact from "../components/forms/contact";
import { graphql, Link } from "gatsby";
import useSocialMedia from "../hooks/useSocialMedia";
import useFooter from "../hooks/useFooter";
import Agent from "../translations/agent";

const ContactPage = ({ data: { node } }) => {
  return (
    <Layout>
      <Hero data={node.relationships.hero} />
      <Section1
        data={node.relationships.info}
        images={node.relationships.images.map((item, index) => ({
          ...item,
          alt: node.images[index].alt,
        }))}
      />
      <Section2 />
    </Layout>
  );
};

const Hero = ({ data }) => {
  const { title, subtitle } = data;
  const url = data.relationships.image?.localFile?.publicURL;

  const action1 = data.relationships.actions[0];

  let backgrounds = [];
  backgrounds = data.relationships.images.map((m) => m.localFile.publicURL);
  if (backgrounds.length === 0) {
    backgrounds.push(url);
  }

  return (
    <HeroInner2
      title={title}
      style={{ backgroundImage: `url("${url}")` }}
      description={subtitle}
      action1={action1}
      backgrounds={backgrounds}
    />
  );
};

const Section1 = ({ data, images }) => {
  const { address, mail } = useSocialMedia();
  const { items } = useFooter();
  const links = items;
  //console.log(links)

  const icons = links.map((item, index) => (
    <div key={index}>
      {item.url.startsWith("/") && (
        <Link to={item.url} title={item.label} className="text-white">
          <img
            src={item.image.src}
            alt={item.image.alt}
            className=" h-[25px]"
          />
        </Link>
      )}
      {!item.url.startsWith("/") && (
        <a
          href={item.url}
          title={item.label}
          target="_blank"
          rel="noreferrer"
          className="text-white"
        >
          <img
            src={item.image.src}
            alt={item.image.alt}
            className=" h-[25px]"
          />
        </a>
      )}
    </div>
  ));

  return (
    <SectionGrid
      className="mt-8 lg:mt-0 "
      classNameGrid="gap-8 grid-cols-1  lg:grid-cols-[3fr_9fr] "
    >
      <div className="bg-black text-white ">
        <div className="hidden lg:block">
          <img src={images[0].localFile.publicURL} alt={images[0].alt} />
        </div>

        <div className={"text-center py-12 "}>
          <Title className="text-[40px] font-bold">ZTrikes</Title>
          <Description>
            <div>{address}</div>
            <div> {mail} </div>
          </Description>

          <div className="flex flex-row space-x-3 space-x-reverse justify-center mt-8  text-white">
            {icons}
          </div>
        </div>
        <div className="hidden lg:block">
          <img src={images[1].localFile.publicURL} alt={images[1].alt} />
        </div>
      </div>
      <div className="max-w-[800px] p-8 lg:p-24 space-y-12 lg:space-y-24 mx-auto">
        <div className="">
          <SubTitle className="text-[20px] text-[#707070]">
            {data.subtitle}
          </SubTitle>
          <Title className="text-[40px] font-bold ">{data.title}</Title>
          <Html>{data.description[0].processed}</Html>
        </div>
        <div>
          <Title className="text-2xl lg:text-[40px] font-meduim pb-2">
            {Agent.translate("contact title")}
          </Title>
          <FormContact
            className=""
            classNameForm="space-y-8 border-primary flex flex-col"
          />
        </div>
      </div>
    </SectionGrid>
  );
};

const Section2 = () => {
  return (
    <div className="">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d107060.59143796617!2d35.65291535655473!3d33.013173635458045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151c1e30626b5db1%3A0x7cbbb48e14cb4c3f!2z15LXk9eV16o!5e0!3m2!1siw!2sil!4v1674123371153!5m2!1siw!2sil"
        className="w-full"
        height="450"
        // _style="border:0;"
        //allowfullscreen=""
        loading="lazy"
        //referrerpolicy="no-referrer-when-downgrade"
        title={Agent.translate("maps directions")}
      ></iframe>
    </div>
  );
};

export default ContactPage;

export const query = graphql`
  query contactus($id: String!) {
    node: nodeContactUs(id: { eq: $id }) {
      id
      title
      metatag {
        attributes {
          content
          name
        }
        tag
      }
      path {
        alias
      }
      images: field_images {
        alt
        title
      }
      relationships {
        hero: field_hero {
          ...HeroContent
        }
        info: field_info {
          ...BlockContent
        }
        images: field_images {
          ...Image
        }
      }
    }
  }
`;
