import React from "react"
import SectionBase from "../base";
//import { Title } from "../../atoms/titles";
//import { Description } from "../../atoms/text";

import Grid from "../../grids/base"
//import ProductCard from "../../cards/product"
import { ProductCardExample } from "../../cards/product";

const SectionGrid = ({
  title = '',
  description = '',
  className = "",
  classNameGrid = "",
  style = {},
  children
}) => {
  return (
    <SectionBase className={className} >
      <Grid className={classNameGrid}>
        {children}
      </Grid>
    </SectionBase>
  )
}

// full width, with image bg or video in the background
const SectionGridExample = () => {
  return (
    <SectionGrid
      className="pt-32 pb-12 lg:py-24 bg-pink-200"
      classNameGrid="gap-16 mx-32 grid-cols-[1fr_3fr_1fr]"
    >
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
      <ProductCardExample className="bg-white p-2" />
    </SectionGrid>

  )
}

export default SectionGrid;
export { SectionGridExample };
