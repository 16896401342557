import React, { useState } from "react"
import axios from "axios"
import { useFormik } from 'formik';
import Agent from "../../translations/agent";

const FormContact = ({ className, classNameForm }) => {

  const [messages, setMessages] = useState([])

  const addMessage = (message) => {
    const temp = messages
    temp.push(message)
    //console.log('temp', temp)
    setMessages(temp)
  }

  const clearMessages = () => {
    setMessages([])
  }


  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      text: '',
    },

    onSubmit: values => {
      clearMessages()

      //alert(JSON.stringify(values, null, 2));
      //return
      const headers = {
        //'Content-Type': 'application/json',
        'content-type': 'application/json',
        'cache-control': 'no-cache',
        //'Authorization': 'JWT fefege...'
      }

      /* const data = {
        field_email: [values.email],
        contact_form: 'newsletter_receptions',
        subject: ['newsletters'],
        message: ['no need but require']
      } */

      const data = {
        "field_name": [values.name],
        "field_phone": [values.phone],
        "field_email": [values.email],
        "contact_form": "feedback",
        "subject": ["תמיכה"],
        "message": [values.text]
      }

      axios.post(
        'https://cms.ztrikes.com/entity/contact_message',
        JSON.stringify(data),
        { headers: headers }
      )
        .then(function (response) {
          console.log(response.data);
          addMessage({ message: Agent.translate('Success. your submission received successfully.') })

        })
        .catch(function (error) {
          console.log(error);
          addMessage({ message: Agent.translate('Error') + ': ' + error.message })
        })
        .then(() => {
          formik.setSubmitting(false)
        })

    },
    validate
  });

  console.log(formik)
  return (
    <div className={className} >
      <div>
        {formik.isSubmitting && <div>{Agent.translate('Submitting...')}</div>}
        {messages.map((m, index) => {
          return (
            <div key={index}>{m.message}</div>
          )
        })}
        {Object.entries(formik.errors).map((m, index) => {
          return (
            <div className="text-red-600 mb-1" key={index}>{m[1]}</div>
          )
        })}
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className={classNameForm}>
          <input
            id="name"
            name="name"
            type="text"
            placeholder="שם"
            className="border border-primary py-2 px-2 inline-block w-full"
            onChange={formik.handleChange}
            value={formik.values.name}
          />

          <input
            id="phone"
            name="phone"
            type="text"
            placeholder="טלפון"
            className="border border-primary py-2 px-2  inline-block w-full"
            onChange={formik.handleChange}
            value={formik.values.phone}
          />

          <input
            id="email"
            name="email"
            type="email"
            placeholder="אימייל"
            className="border border-primary py-2 px-2  inline-block w-full"
            onChange={formik.handleChange}
            value={formik.values.email}
          />

          <textarea
            id="text"
            name="text"
            placeholder="כתיבת הודעה פה"
            className="border border-primary py-2 px-2  inline-block w-full"
            rows={5}
            onChange={formik.handleChange}
            value={formik.values.text}
          >

          </textarea>
          <button
            className="button-primary  w-full"
            type="submit"
            disabled={formik.isSubmitting}
          >שלח</button>
        </div>
      </form>
    </div>

  )
}


const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = Agent.translate('Name Required');
  } /* else if (values.name.length > ) {
    errors.name = 'Must be 15 characters or less';
  } */

  if (!values.phone) {
    errors.phone = Agent.translate('Phone Required');
  } else if (values.phone.length < 7) {
    errors.phone = Agent.translate('Phone Must be 7 characters or more');
  }

  if (!values.email) {
    errors.email = Agent.translate('Email Required');
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = Agent.translate('Invalid email address');
  }


  if (!values.text) {
    errors.text = Agent.translate('Body Required');
  }

  return errors;
};


export default FormContact;

